<template>
  <site-events :events="events" :pages="eventsPages" :count="eventsCount" @refresh-request="refreshEvents"
    @download-request="downloadEvents" />
</template>

<script>
import EventService from "@/services/event.service"
import { format } from "date-fns"
import DownloadEventsDialog from "@/components/DownloadEventsDialog.vue"

export default {
  name: "Events",
  data() {
    return {
      events: [],
      eventsPages: 0,
      eventsCount: 0,
    }
  },
  computed: {},
  methods: {
    refreshEvents(context) {
      EventService.fetchEvents(
        context.query,
        context?.page,
        context?.ordering
      ).then(response => {
        if (response?.data) {
          this.eventsPages = response.data.pages
          this.eventsCount = response.data.count
          this.events = response.data.results
        }
      })
    },
    async downloadEvents(context) {
      console.log('downloadEvents :>> ', context);
      const result = await this.$dialog.show(DownloadEventsDialog, {
        title: "",
        waitForResult: true,
      });
      if (result === false || result === undefined) {
        return;
      }

      const query = {
        ...context.query,
        ...result,
      }
      console.log('result :>> ', query);
      EventService.download(
        null,
        query,
      ).then(response => {
        console.log("ok", response.data)
        const blob = new Blob([response.data], {
          type: "application/pdf;charset=utf-8;"
        })
        const link = document.createElement("a")
        link.href = URL.createObjectURL(blob)
        link.download = `raport_${format(
          new Date(),
          "yyyMMddHHmmss"
        )}.pdf`
        link.click()
        URL.revokeObjectURL(link.href)
      })
    }
  }
}
</script>
